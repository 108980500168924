<div class="p-3">
  <h2>Feedbacks awaiting</h2>
  <p class="text-xs">
    These mentees haven't filled out the mid or final feedback yet. You'll see here mentees with feedbacks due in the
    past. Please remind them to fill out the mid or final feedback to get the most precise data from the program.
  </p>
</div>
<div class="container-iframe">
  <iframe [src]="url" class="responsive-iframe"></iframe>
</div>
