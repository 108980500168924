import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppState } from 'src/app/state/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Company } from 'src/app/shared/types/company.types';

@Component({
  selector: 'fp-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.scss'],
})
export class MatchesComponent implements OnInit {
  constructor(public store: Store<AppState>, private sanitizer: DomSanitizer) {}
  company$: Observable<Company>;
  project_id: string;
  url: SafeResourceUrl;

  ngOnInit() {
    this.company$ = this.store.select(state => state.company);
    this.company$.subscribe(company => {
      if (company) {
        this.project_id = company.project_id;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://lookerstudio.google.com/embed/reporting/' + this.project_id + '/page/lMJ9C'
        );
      }
    });
  }
}
